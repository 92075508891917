import React, { createContext, useContext, useState } from 'react';
import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_HOSPITAL_QUERY } from './queries.js';
import { setLocalStorageEntry } from 'utils/local-storage'

const HOSPITAL_LOCALSTORAGE_KEY = '_transcription_hospital';

const HospitalContext = createContext({});

const HospitalWrapper = ({ children }) => {
  const [currentHospital, setCurrentHospital] = useState(null);
  const [hospitals, setHospitals] = useState(null);

  const { loading } = useQuery(GET_CURRENT_HOSPITAL_QUERY, {
    onCompleted: (data) => {
      setCurrentHospital(data?.currentHospital)
      setHospitals(data?.hospitals)

      setLocalStorageEntry(HOSPITAL_LOCALSTORAGE_KEY, {
        name: data?.currentHospital?.name,
        id: data?.currentHospital?.id
      });
    }
  })

  const setHospital = (hospital) => {
    setCurrentHospital(hospital)
    setLocalStorageEntry(HOSPITAL_LOCALSTORAGE_KEY, {
      name: hospital.name,
      id: hospital.id
    });


    if (window.location.toString().includes('records')) {
      window.location = '/records'
    } else if (window.location.toString().includes('email')) {
      window.location = '/email/inbox'
    }
  }

  return (
    <HospitalContext.Provider
      value={{
        currentHospital,
        hospitals,
        loading,
        setHospital
      }}
    >
      {children}
    </HospitalContext.Provider>
  );
};


const useHospitals = () => {
  const context = useContext(HospitalContext);

  if (context === undefined) {
    throw new Error('HospitalWrapper is missing');
  }
  return context;
};


export { HospitalContext, useHospitals };
export default HospitalWrapper;