import MainLayout from 'components/doctor/main-layout'
import { useState } from 'react'
import { GET_TRANSCRIPTION_LIST } from './queries'
import { useQuery } from '@apollo/client'
import List from './list'
import Pagination from './pagination'
import { useSearchParams } from 'react-router-dom'

const PAGE_SIZE = 20

const TranscriptionsPage = () => {
  const [searchParams, _] = useSearchParams();
  const [filter, setFilter] = useState('All')
  const offset = parseInt(searchParams.get('page')) * PAGE_SIZE || 0

  const { loading, error, data } = useQuery(GET_TRANSCRIPTION_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: filter,
      limit: PAGE_SIZE,
      offset: offset
    }
  });

  const toggleFilter = () => {
    if (filter === 'All') {
      setFilter('Mine')
    }
    
    if (filter === 'Mine') {
      setFilter('All')
    }
  }


  if (loading) return null;
  if (error) return <span>Error : {error.message}</span>;

  return (
    <MainLayout>
      <div className='flex flex-row h-100 w-full' style={{ 'height': 'calc(100vh - 4rem)' }}>
        <div className='flex flex-col w-full h-screen border-l overflow-y-scroll'>
          <div className='w-full py-8 px-8'>
            <h1 className='font-bold text-lg'>Medical Records</h1>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input checked={filter === 'Mine'} onChange={toggleFilter} id="mine" aria-describedby="mine-description" name="mine" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="mine" className="font-medium text-gray-900">Show Only Mine</label>
              </div>
            </div>
            <div className="inline-block min-w-full py-2 align-middle">
              <List transcriptions={data?.transcriptions} />
            </div>
          </div>
          {data.transcriptionPages > 1 && <Pagination pages={data.transcriptionPages} />}
        </div>
      </div>
    </MainLayout>
  )

}

export default TranscriptionsPage