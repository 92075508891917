
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_EMAIL_THREADS_QUERY } from "./queries";
import List from './list'
import Pusher from 'pusher-js'
import { useHospitals } from 'contexts/hospital'

function capitalize(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

const Content = ({ filter}) => {
  const { currentHospital } = useHospitals()

  const { loading, error, data, refetch } = useQuery(GET_EMAIL_THREADS_QUERY, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    const pusher = new Pusher('d10606873286053e4a22', {
      cluster: 'us3',
    });

    const channel = pusher.subscribe(`gmail-inbox-${currentHospital?.id}`)

    channel.bind('updated', function (data) {
      refetch()
    });

    return () => channel.unbind('updated')
  }, [])

  console.log('email threads', data)

  if (loading) return null;
  if (error) return <span>Error : {error.message}</span>;

  return (
    <div className='px-8 py-6 w-full'>
      <h1 className='text-2xl font-bold'>{capitalize(filter)}</h1>
      <div className='w-100 mt-8'>
        {data.emailThreads.length > 0 && <List threads={data.emailThreads} />}
        {data.emailThreads.length == 0 && <p className='text-slate-500'>No emails yet</p>}
      </div>
    </div>
  )

}

export default Content