import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ADMIN_GENERATE_PAYMENT_LINK_MUTATION } from './queries'


const BillingTab = ({ account, refetch }) => {

  const [licenseCount, setLicenseCount] = useState(0)
  const [hardwareCount, setHardwareCount] = useState(0)

  const [generatePaymentLink, _] = useMutation(ADMIN_GENERATE_PAYMENT_LINK_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      refetch()
      setLicenseCount(0)
      setHardwareCount(0)
    },
    variables: {
      accountId: account.id,
      licenseCount: parseInt(licenseCount),
      hardwareCount: parseInt(hardwareCount),
    }
  })

  useEffect(() => {
    setLicenseCount(account.licenseCount)
    setHardwareCount(account.hardwareCount)
  }, [account.licenseCount, account.hardwareCount])

  return (
    <div>
      <p><span className='font-medium text-slate-800'>Current MRR:</span> ${account.mrr}</p>
      <p><span className='font-medium text-slate-800'>Doctor User Accounts:</span> {account.doctorCount}</p>

      <div className='border rounded-md p-8'>
        {!account.stripeSubscriptionId && (
          <div>
            <p className='font-bold'>Generate Payment Link</p>
            <div className="mt-4">
              <label htmlFor="licenseCount" className="block text-sm font-medium leading-6 text-gray-900">Licenses</label>
              <div>
                <input value={licenseCount} onChange={(e) => setLicenseCount(e.target.value)} id="licenseCount" name="licenseCount" placeholder="0" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="hardwareCount" className="block text-sm font-medium leading-6 text-gray-900">Hardware Setups</label>
              <div>
                <input value={hardwareCount} onChange={(e) => setHardwareCount(e.target.value)} id="hardwareCount" name="hardwareCount" placeholder="0" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <button onClick={generatePaymentLink} type="button" className="mt-4 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto">Generate New Payment Link</button>
          </div>
        )}
        
        {account.stripeCheckoutUrl && !account.stripeSubscriptionId && (
          <div className='mt-4'>
            <label htmlFor="stripeCheckoutUrl" className="block text-sm font-medium leading-6 text-gray-900">Stripe Checkout Url</label>
            <div>
              <input disabled value={account.stripeCheckoutUrl} id="stripeCheckoutUrl" name="stripeCheckoutUrl" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
            </div>
            <p className='text-sm'><span className='font-bold'>License Count:</span> {account.licenseCount}</p>
            <p className='text-sm'><span className='font-bold'>Hardware Count:</span> {account.hardwareCount}</p>
          </div>
        )}
        {account.stripeSubscriptionId && (
          <div>
            <a href={account.stripeSubscriptionUrl} target='_blank' className='text-blue-500' rel="noreferrer">View Subscription in Stripe</a>
            <p className='text-sm'><span className='font-bold'>License Count:</span> {account.licenseCount}</p>
            <p className='text-sm'><span className='font-bold'>Hardware Count:</span> {account.hardwareCount}</p>
          </div>
        )}
        
      </div>
    </div>
  )


}

export default BillingTab