import { gql } from "@apollo/client"

export const GENERATE_EMAIL_RESPONSE_MUTAITON = gql`
  mutation GenerateEmailResponse(
    $emailThreadId: ID!
  ) {
    generateEmailResponse(
      emailThreadId: $emailThreadId
    ) {
      body
    }
  }
`

export const SEND_THREAD_RESPONSE_MUTATION = gql`
  mutation SendThreadResponse(
    $emailThreadId: ID!,
    $to: String!,
    $cc: String,
    $bcc: String,
    $subject: String!,
    $body: String!,
  ) {
    sendThreadResponse(
      emailThreadId: $emailThreadId,
      to: $to,
      cc: $cc,
      bcc: $bcc,
      subject: $subject,
      body: $body,
    ) {
      success
    }
  }
`
