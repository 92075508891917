import MainLayout from 'components/admin/main-layout';
import Content from './content'


const AdminAccounts = () => {

  return (
    <MainLayout>
      <div className='flex flex-row h-100 w-full' style={{ 'height': 'calc(100vh - 4rem)' }}>
          <Content />
      </div>
    </MainLayout>
  )

}

export default AdminAccounts