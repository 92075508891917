import { useState, useEffect } from 'react';

const EmailTab = ({ transcription, refetch }) => {

  const [email, setEmail] = useState()

  useEffect(() => {
    setEmail(transcription.clientEmailContent)

  }, [transcription])

  return (
    <div>
      <textarea
        className={`w-full mb-4 rounded-lg border-gray-300 ${transcription.status == 'Generating Email' && 'bg-gray-200'}`}
        placeholder="Edit Client Email here..."
        value={email}
        disabled={transcription.status == 'Generating Email'}
        rows={15}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div>
        {/* <button onClick={onSave} className='text-white bg-black px-4 py-2 rounded-md mr-2'>Save</button> */}
        {/* <button onClick={onSaveAndPublish} className='text-white bg-black px-4 py-2 rounded-md'>Save + Publish</button> */}
      </div>
    </div>
  )
}

export default EmailTab