import { useEffect } from "react"
import { useQuery } from "@apollo/client"
import { GET_EMAIL_THREAD_QUERY } from './queries'
import { useParams } from "react-router-dom"
import EmailMessage from './email-message'
import ContextSidebar from './context-sidebar'
import ActionBar from './action-bar'
import Pusher from 'pusher-js'

const Content = () => {
  const { emailThreadId } = useParams()

  const { data, error, refetch } = useQuery(GET_EMAIL_THREAD_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      emailThreadId: emailThreadId
    }
  })

  useEffect(() => {
    const pusher = new Pusher('d10606873286053e4a22', {
      cluster: 'us3',
    });

    const channel = pusher.subscribe(`gmail-inbox-thread-${emailThreadId}`)

    channel.bind('updated', function (data) {
      refetch()
    });

    return () => channel.unbind('updated')
  }, [])

  console.log('email emailThreadId', emailThreadId)
  console.log('email message', data)

  if (!data) { return (
    <div className='px-8 py-8 w-full'>
      &nbsp;
    </div>
  ) }
  if (error) { return <p>Error: {error.message}</p> }

  return (
    <div className='flex flex-row w-full'>
      <div className='px-8 py-8 w-full overflow-y-scroll'>
        <h2 className="text-xl font-medium">{data.emailThread.subject}</h2>
        <div className='w-100 w-full'>
          {data.emailThread.emailMessages.map(emailMessage => <EmailMessage message={emailMessage} />)}
          <ActionBar emailThread={data.emailThread} refetchThread={refetch} />
        </div>
      </div>
      <ContextSidebar clientEmail={data.emailThread.correspondentEmail} />
    </div>
  )

}

export default Content