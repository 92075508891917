import { useNavigate } from "react-router-dom";

const Row = ({ thread }) => {

  const navigate = useNavigate()

  const onSelect = (emailId) => {
    navigate(`/email/${emailId}`);
  }

  // friendlyAppointmentScheduledAt
  // patientName
  // userFullName

  return (
    <tr key={thread.id} className={`cursor-pointer`} onClick={() => onSelect(thread.id)}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className={`text-gray-900 mr-8 ml-4 flex flex-row items-center ${thread.isRead ? '' : 'font-medium'}`}>
              {!thread.isRead ? (
                <span className='inline mr-4'>
                  <svg className="h-2 w-2 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                    <circle cx="3" cy="3" r="3" />
                  </svg>
                </span>
              ) : (
                  <span className='inline mr-7' />
              )}
              {thread.correspondent}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center overflow-hidden">
          <div>
            <div className={`text-gray-900 overflow-hidden ${thread.isRead ? '' : 'font-medium'}`}>{thread.subject}</div>
            <div className="font-medium text-gray-400 truncate w-96">{thread.snippet}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="text-gray-900 truncate">{thread.friendlyLatestSentAt}</div>
          </div>
        </div>
      </td>
      {/* <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-500 truncate w-96">{transcription.proofreadContent || transcription.revisedContent || transcription.transcribedContent}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{transcription.friendlyUploadedDate}</div>
            <div className="font-medium text-gray-500 truncate w-40">{transcription.friendlyUploadedTime}</div>
          </div>
        </div>
      </td> */}
    </tr>
  )
}

const List = ({ threads }) => {

  return (
    <table className="min-w-full divide-y divide-gray-300">
      {/* <thead>
        <tr>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Appointment</th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Hospital</th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">User</th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Content</th>
          <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Uploaded At</th>
          <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
          <th className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
              </th> 
        </tr>
      </thead> */}
      <tbody className="divide-y divide-gray-200 bg-white">
        {
          threads.map((thread) => (
            <Row key={thread.id} thread={thread} />
          ))
        }
      </tbody>
    </table>
  )
}

export default List