import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import StatusBadge from 'components/admin-account-status-badge';
import NewAccountModal from '../new-account-modal'

const Row = ({ account }) => {

  const navigate = useNavigate()

  const onSelect = (accountId) => {
    navigate(`/admin/accounts/${accountId}`);
  }

  // friendlyAppointmentScheduledAt
  // patientName
  // userFullName

  return (
    <tr key={account.id} className='cursor-pointer' onClick={() => onSelect(account.id)}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{account.name}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{account.hospitalCount}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{account.userCount}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{account.licenseCount}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{account.hardwareCount}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">${account.mrr}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <StatusBadge status={account.status} />
      </td>
    </tr>
  )
}
const List = ({ accounts, refetch }) => {

  const [modalOpen, setModalOpen] = useState(false)

  console.log('modalOpen', modalOpen)

  if (!accounts) { 
    return null
  }
  return (
    <>
      <div className='w-full py-8 px-8'>
        <div className='flex flex-row items-center justify-between'>
          <h1 className='font-bold text-lg'>Accounts</h1>
          <button onClick={() => setModalOpen(true)} className='rounded-md px-4 py-2 bg-blue-500 text-white font-medium'>Add Account</button>
        </div>
        <div className="inline-block min-w-full py-2 align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
                <tr>
                    <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                    <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Hospitals</th>
                    <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Users</th>
                    <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Licenses</th>
                    <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Hardware</th>
                    <th className="py-3.5 text-left text-sm font-semibold text-gray-900">MRR</th>
                    <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {
                accounts.map((account) => (
                  <Row key={account.id} account={account} />
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <NewAccountModal open={modalOpen} refetch={refetch} />
    </>
  )
}

export default List