import { useState, useEffect } from 'react';

const SoapTab = ({ transcription }) => {

  const [soap, setSoap] = useState()

  useEffect(() => {
    setSoap(transcription.soapContent)

  }, [transcription])

  

  return (
    <div>
      <textarea
        className={`w-full mb-4 rounded-lg border-gray-300 ${transcription.status == 'Generating SOAP' && 'bg-gray-200'}`}
        placeholder="Edit SOAP Notes here..."
        value={soap}
        disabled={transcription.status == 'Generating SOAP'}
        rows={15}
        onChange={(e) => setSoap(e.target.value)}
      />
      <div>
        {/* <button onClick={onSave} className='text-white bg-black px-4 py-2 rounded-md mr-2'>Save</button> */}
        {/* <button onClick={onSaveAndPublish} className='text-white bg-black px-4 py-2 rounded-md'>Save + Publish</button> */}
      </div>
    </div>
  )
}

export default SoapTab