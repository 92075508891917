import { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import { ADMIN_CREATE_USER_MUTATION } from './queries'
import Select from 'react-select'

const NewHospitalModal = ({ refetch, open, account }) => {

  const [email, setEmail] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [postNominal, setPostNominal] = useState(null)
  const [kind, setKind] = useState('doctor')
  const [phone, setPhone] = useState(null)
  const [password, setPassword] = useState(null)
  const [hospitalIds, setHospitalIds] = useState([])
  const [sendEmail, setSendEmail] = useState(false)
  const [analyticsAccess, setAnalyticsAccess] = useState(false)

  const [isVisible, setIsVisible] = useState(false)

  console.log('hospitalIds', hospitalIds)


  useEffect(() => {
    setIsVisible(open)
  }, [open])

  const [createUser, _] = useMutation(ADMIN_CREATE_USER_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      refetch()
      setFirstName(null)
      setLastName(null)
      setPostNominal(null)
      setKind('doctor')
      setPhone(null)
      setPassword(null)
      setHospitalIds([])
      setIsVisible(false)
      setAnalyticsAccess(false)
    },
    variables: {
      accountId: account.id,
      hospitalIds,
      email,
      firstName,
      lastName,
      postNominal,
      kind,
      phone,
      password,
      sendEmail,
      analyticsAccess
    }
  })

  if (!isVisible) return null;

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="flex flex-col sm:items-start w-full">
              <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left mb-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">New User</h3>
                <p className='text-red-400'>Don't forget to add to App Store Connect / TestFlight!</p>
                <p>Creating this user will send a setup email</p>
              </div>
              <div className="flex flex-col px-4 w-full">
                <div className="mt-4">
                  <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                  <div>
                    <input value={firstName} onChange={(e) => setFirstName(e.target.value)} id="firstName" name="firstName" placeholder="Jane" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                  <div>
                    <input value={lastName} onChange={(e) => setLastName(e.target.value)} id="lastName" name="lastName" placeholder="Doe" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="postNominal" className="block text-sm font-medium leading-6 text-gray-900">Suffix/Post Nominal</label>
                  <div>
                    <input value={postNominal} onChange={(e) => setPostNominal(e.target.value)} id="postNominal" name="postNominal" placeholder="DVM" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                  <div>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" placeholder="doctor@hospital.com" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                  <div>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" placeholder="password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                  <div>
                    <input value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" name="phone" placeholder="503-380-5300" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="kind" className="block text-sm font-medium leading-6 text-gray-900">Type</label>
                  <div>
                    <select value={kind} onChange={(e) => setKind(e.target.value)} id="kind" name="kind" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6">
                      <option value="manager">Manager</option>
                      <option value="doctor">Doctor</option>
                      <option value="nurse">Nurse</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div className='mt-4'>
                  <input checked={analyticsAccess} onChange={() => setAnalyticsAccess(prev => !prev)} id="analyticsAccess" name="analyticsAccess" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                  <label htmlFor="mine">Has Analytics Access?</label>
                </div>
                <div className="mt-4">
                  <label htmlFor="hospitalIds" className="block text-sm font-medium leading-6 text-gray-900">Hospitals</label>
                  <div>
                    <Select 
                      isMulti 
                      defaultValue={[]}
                      onChange={(list) => setHospitalIds(list.map(i => i.value))}
                      name="hospitalIds"
                      options={account.hospitals.map(hospital => ({
                        value: hospital.id,
                        label: hospital.name
                      }))} 
                    />
                  </div>
                </div>
                <div className='mt-4'>
                  <input checked={sendEmail} onChange={() => setSendEmail(prev => !prev)} id="sendEmail" name="sendEmail" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                  <label htmlFor="mine">Send Email?</label>
                </div>
              </div>
            </div>
            <div className="mt-5 mt-20 sm:flex sm:pl-4">
              <button onClick={createUser} type="button" className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto">Submit</button>
              <button onClick={() => setIsVisible(false)} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewHospitalModal