import { useState, useEffect } from 'react';
import MainLayout from 'components/admin/main-layout';
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client';
import { GET_ADMIN_TRANSCRIPTION_QUERY, PROOFREAD_TRANSCRIPTION_MUTATION } from './queries'
import Pusher from 'pusher-js'
import StatusBadge from 'components/admin-transcription-status-badge';
import NewUserModal from './new-user-modal'

const Row = ({ user }) => {

  const [showPassword, setShowPassword] = useState(false)

  return (
    <tr key={user.id}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.firstName}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.lastName}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.email}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{user.phone}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900">{!showPassword ? <span>********<span onClick={() => setShowPassword(true)} className='ml-2 text-blue-600 text-sm cursor-pointer'>Reveal</span></span> : <span className="font-mono">{user.password}</span>}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900 capitalize">{user.hospitalCount}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div>
            <div className="font-medium text-gray-900 capitalize">{user.kind}</div>
          </div>
        </div>
      </td>
    </tr>
  )
}

const UsersTab = ({ account, refetch }) => {

  const [modalOpen, setModalOpen] = useState(false)

  if (account.users.length == 0) {
    return (
      <>
        <div className="flex flex-row justify-between items-center">
          <p>No users yet</p>
          <button onClick={() => setModalOpen(true)} className='rounded-md px-4 py-2 bg-blue-500 text-white font-medium'>Add User</button>
        </div>
        <NewUserModal open={modalOpen} refetch={refetch} account={account} />
      </>
    )
  }
  return (
    <>
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="flex flex-row-reverse">
          <button onClick={() => setModalOpen(true)} className='rounded-md px-4 py-2 bg-blue-500 text-white font-medium'>Add User</button>
        </div>
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">First Name</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Last Name</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Password</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Hospitals</th>
              <th className="py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {
              account.users.map((user) => (
                <Row key={user.id} user={user} />
              ))
            }
          </tbody>
        </table>
      </div>
      <NewUserModal open={modalOpen} refetch={refetch} account={account}/>
    </>
  )


}

export default UsersTab