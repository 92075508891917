import { useState, useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { CREATE_TELEMEDICINE_CALL_MUTATION, GET_DOCTOR_USERS_QUERY, GET_WEEKLY_APPOINTMENTS_QUERY } from './queries'
import Select from 'react-select'
// import Datepicker from "tailwind-datepicker-react"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useHospitals } from 'contexts/hospital'

const NewTelemedicineCallModal = ({ refetch, open, setIsOpen }) => {

  const [temporaryName, setTemporaryName] = useState("")
  const [userId, setUserId] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [showManualInputBox, setShowManualInputBox] = useState(true)
  const [appointmentDatetime, setAppointmentDatetime] = useState(new Date())
  const [appointmentId, setAppointmentId] = useState()
  const { currentHospital } = useHospitals();

  useEffect(() => {
    setIsVisible(open)  
  }, [open])

  const [createAppointment, _] = useMutation(CREATE_TELEMEDICINE_CALL_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      refetch()
      setTemporaryName(null)
      setUserId(null)
      setAppointmentDatetime(new Date())
      setIsVisible(false)
      setShowManualInputBox(true)
    },
    variables: {
      temporaryName,
      userId,
      appointmentDatetime,
      appointmentId,
    }
  })

  const { data } = useQuery(GET_DOCTOR_USERS_QUERY, {
    fetchPolicy: 'no-cache',
  });


  const isIntegrationEnabled = currentHospital?.integrationEnabled;

  
  const { data: appointmentsData } = useQuery(
    GET_WEEKLY_APPOINTMENTS_QUERY,
    {
      fetchPolicy: 'no-cache',
      skip: !isIntegrationEnabled
    }
  );

  useEffect(() => {
    const hasAppointments = appointmentsData?.weeklyAppointments && appointmentsData?.weeklyAppointments?.length > 0;

    if (isIntegrationEnabled && hasAppointments) {
      setShowManualInputBox(false)
    } else {
      setShowManualInputBox(true)
    }
  }, [currentHospital, appointmentsData])

  

  if (!isVisible) return null;


  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const onAppointmentSelect = ({ value }) => {
    if (value === 'new') {
      setShowManualInputBox(true)
    } else {
      setShowManualInputBox(false);
      setAppointmentId(value);
      const appointment = appointmentsData?.weeklyAppointments.find(appointment => appointment.id === value);
      if (appointment) {
        setAppointmentDatetime(new Date(appointment.scheduledAt))
      }
    }
  }

  // Organize appointments by date
  const groupedAppointments = appointmentsData?.weeklyAppointments.reduce((groups, appointment) => {
    const date = appointment.friendlyDate;
    groups[date] = groups[date] || [];
    groups[date].push({
      value: appointment.id,
      label: `${appointment.friendlyTime}: ${appointment.patientName}`
    });
    return groups;
  }, {});

  // Create grouped options for react-select
  const groupedOptions = [
    {
      value: 'new',
      label: 'Add New Appointment'
    },
    ...Object.entries(groupedAppointments || {}).map(([date, appointments]) => ({
      label: date,
      options: appointments
    }))
  ];

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="flex flex-col sm:items-start w-full">
              <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left mb-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">New Telemedicine Call</h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">Schedule a call now for the doctor to join at appointment time.</p>
                </div>
              </div>
              <div className="flex flex-col px-4 w-full">
                {!showManualInputBox && <div className="mb-4">
                  <label htmlFor="appointmentId" className="block text-sm font-medium leading-6 text-gray-900">Appointment</label>
                  <div>
                    <Select
                      defaultValue={[]}
                      name="appointmentId"
                      onChange={onAppointmentSelect}
                      options={groupedOptions}
                    />
                  </div>
                </div>}
                {showManualInputBox && <div className="mb-4">
                  <label htmlFor="temporaryName" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                  <div>
                    <input value={temporaryName} onChange={(e) => setTemporaryName(e.target.value)} id="temporaryName" name="temporaryName" required placeholder="Fluffy 2pm Dr Jones" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>}
                <div className="mb-4">
                  <label htmlFor="userId" className="block text-sm font-medium leading-6 text-gray-900">Doctor</label>
                  <div>
                    <Select
                      defaultValue={[]}
                      name="userId"
                      onChange={({value}) => setUserId(value)}
                      options={data?.doctorUsers.map(user => ({
                        value: user.id,
                        label: user.emailSignature
                      }))}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="scheduledAt" className="block text-sm font-medium leading-6 text-gray-900">Scheduled Time</label>
                  <div>
                    <DatePicker
                      selected={appointmentDatetime}
                      onChange={(datetime) => setAppointmentDatetime(datetime)}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      timeIntervals={15}
                      filterTime={filterPassedTime}
                      showTimeSelect
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:flex sm:pl-4">
              <button onClick={createAppointment} type="button" className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto">Submit</button>
              <button onClick={() => { setIsVisible(false); setIsOpen(false); setShowManualInputBox(true); }} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewTelemedicineCallModal