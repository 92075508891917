
import { GET_ADMIN_ACCOUNTS_LIST } from './queries'
import { useQuery } from '@apollo/client'
import List from './list'

const Content = () => {
  const { loading, error, data, refetch } = useQuery(GET_ADMIN_ACCOUNTS_LIST, {
    fetchPolicy: 'no-cache',
  });

  if (loading) return null;
  if (error) return <span>Error : {error.message}</span>;

  return (
    <div className='flex flex-col w-full h-screen border-l overflow-y-scroll'>
      <List accounts={data?.adminAccounts} refetch={refetch} />
    </div>
  )

}

export default Content