import { useState, useRef, useEffect } from "react"
import { useClickOutside } from 'utils/click-outside'
import { useMutation } from '@apollo/client'
import { GENERATE_EMAIL_RESPONSE_MUTAITON, SEND_THREAD_RESPONSE_MUTATION } from './queries.js'


const Reply = ({ emailThread, composeMode, returnToActionBar, refetchThread }) => {

  const clickRef = useRef();

  const onClickOutside = () => {
    if (showMenu) {
      console.log('hide menu')
      setShowMenu(false)
    }
  }

  const startEmailResponseGeneration = () => {
    setBody('Loading...')
    generateEmailResponse()
  }

  const [generateEmailResponse, _] = useMutation(GENERATE_EMAIL_RESPONSE_MUTAITON, {
    errorPolicy: "all",
    onError: (e) => {
      console.log(e)
      setBody(null)
    },
    onCompleted: (data) => {
      console.log('generateEmailResponse', data)
      setBody(data.generateEmailResponse.body)
    },
    variables: {
      emailThreadId: emailThread.id,
    }
  })

  useClickOutside(clickRef, onClickOutside);

  const [subject, setSubject] = useState(emailThread.subject)
  const [mode, setMode] = useState(composeMode)
  const [to, setTo] = useState(composeMode == 'reply' ? emailThread.correspondentEmail : '')
  const [ccVisible, setCCVisible] = useState(false)
  const [cc, setCC] = useState(null)
  const [bccVisible, setBCCVisible] = useState(false)
  const [bcc, setBCC] = useState(null)
  const [body, setBody] = useState()
  const [showMenu, setShowMenu] = useState(false)

  const [sendThreadResponse, _2] = useMutation(SEND_THREAD_RESPONSE_MUTATION, {
    errorPolicy: "all",
    onError: (e) => {
      console.log(e)
      setBody(null)
    },
    onCompleted: (data) => {
      console.log('sendThreadResponse', data)
      setBody(null)
      returnToActionBar()
      refetchThread()
      // setBody(data.generateEmailResponse.body)
    },
    variables: {
      emailThreadId: emailThread.id,
      to: to,
      cc: cc,
      bcc: bcc,
      subject: subject,
      body: body,
    }
  })


  console.log('reply', emailThread)
  console.log('showMenu', showMenu)

  const toggleMenu = (e) => {
    console.log('toggleMenu')
    setShowMenu(prev => !prev)
  }

  const changeModeToForward = () => {
    setMode('forward')
    setTo('')
    setSubject(`Fwd: ${emailThread.subject}`)
  }

  const changeModeToReply = () => {
    setMode('reply')
    setTo(emailThread.correspondentEmail)
    setSubject(emailThread.subject)
  }

  console.log('content', body)
  console.log('to', to)
  console.log('!content && !to', to)

  return (
    <div className='bg-white shadow border rounded-md mb-20'>
      <div className='w-100 py-4 px-4'>
        <div className='font-medium mb-4 flex flex-row items-start'>
          {mode == 'reply' && (
            <div onClick={toggleMenu} ref={clickRef} className='relative hover:shadow bg-white rounded-sm mr-2 py-1 px-2 cursor-pointer'>
              <svg className='w-4 h-4 inline' data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"></path>
              </svg>
              {showMenu && (<div className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-min -translate-x-8 px-4">
                <div className="w-56 shrink rounded-sm bg-white px-4 py-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                  <a onClick={changeModeToForward}href="#" className="block p-1 group ">
                    <svg className='w-4 h-4 inline text-slate-800 mr-2 mb-0.5 group-hover:text-indigo-600' data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"></path>
                    </svg>
                    <span className='group-hover:text-indigo-600'>Forward</span>
                  </a>
                  <a onClick={() => setCCVisible(true)} href="#" className="block p-1 hover:text-indigo-600">Add CC</a>
                  <a onClick={() => setBCCVisible(true)} href="#" className="block p-1 hover:text-indigo-600">Add BCC</a>
                </div>
              </div>)}
            </div>
          )}
          {mode == 'forward' && (
          <div onClick={toggleMenu} className='relative hover:shadow bg-white rounded-sm mr-2 py-1 px-2 cursor-pointer'>
              <svg className='w-4 h-4 inline' data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"></path>
              </svg>
              {showMenu && (<div className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-min -translate-x-8 px-4">
                <div className="w-56 shrink rounded-sm bg-white px-4 py-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                  <a onClick={changeModeToReply}href="#" className="block p-1 group ">
                    <svg className='w-4 h-4 inline text-slate-800 mr-2 mb-0.5 group-hover:text-indigo-600' data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"></path>
                    </svg>
                    <span className='group-hover:text-indigo-600'>Reply</span>
                  </a>
                  <a onClick={() => setCCVisible(true)} href="#" className="block p-1 hover:text-indigo-600">Add CC</a>
                  <a onClick={() => setBCCVisible(true)} href="#" className="block p-1 hover:text-indigo-600">Add BCC</a>
                </div>
              </div>)}
            </div>
          )}
          <div className='flex flex-col items-start justify w-full'>
            <div className='flex flex-row items-center mb-2 w-full'>
              <span className='font-medium mr-2'>To:</span>
              <input className='border-none w-full px-2 py-1' name='to' type='email' value={to} onChange={(e) => setTo(e.target.value)}/>
            </div>
            {ccVisible && (<div className='flex flex-row items-center mb-2 w-full'>
              <span className='font-medium mr-2'>CC:</span>
              <input className='border-none w-full px-2 py-1' name='cc' type='email' value={cc} onChange={(e) => setCC(e.target.value)}/>
            </div>)}
            {bccVisible && (<div className='flex flex-row items-center mb-2 w-full'>
              <span className='font-medium mr-2'>BCC:</span>
              <input className='border-none w-full px-2 py-1' name='bcc' type='email' value={cc} onChange={(e) => setBCC(e.target.value)}/>
            </div>)}
          </div>
        </div>
        <textarea value={body} onChange={(e) => setBody(e.target.value)} className='w-full h-72 bg-white border-none rounded-sm' placeholder='Reply here...' row={8}></textarea>
      </div>
      <div className='flex flex-row justify-between border-t pt-4 w-full px-4 pb-4'>
        <div>
          <button onClick={startEmailResponseGeneration} className='hover:shadow p-2 rounded-sm group'>
            <svg className='text-slate-600 h-4 w-4 group-hover:text-slate-800' data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path clipRule="evenodd" fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"></path>
            </svg>
          </button>
        </div>
        <button onClick={sendThreadResponse} disabled={!(body && to)} type="button" className={`rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ${!(body && to) ? 'bg-gray-400 ring-gray-300' : 'bg-black ring-gray-800 hover:bg-gray-800'}`}>Send</button>
      </div>
    </div>
  )

}

export default Reply