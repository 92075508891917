import { Navigate } from "react-router-dom";
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_USER_QUERY, GET_CURRENT_ADMIN_USER_QUERY } from './queries.js';
import { setLocalStorageEntry, removeLocalStorageEntry } from 'utils/local-storage'

const ADMIN_USER_LOCALSTORAGE_KEY = '_transcription_admin_user';
const DOCTOR_USER_LOCALSTORAGE_KEY = '_transcription_doctor_user';

const AuthenticationContext = createContext({});

const AuthWrapper = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentAdminUser, setCurrentAdminUser] = useState(null);

  const { loading: adminLoading } = useQuery(GET_CURRENT_ADMIN_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentAdminUser(data?.currentAdminUser)
    }
  })

  const { loading: doctorLoading } = useQuery(GET_CURRENT_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentUser(data?.currentUser)
    }
  })

  const logAdminUserIn = useCallback((user) => {
    setLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY, {
      authToken: user.authToken,
      id: user.id
    });
    removeLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY);
    setCurrentAdminUser(user);
    setCurrentUser(null);
  }, []);


  const logAdminUserOut = useCallback(() => {
    setCurrentAdminUser(null);
    removeLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY);
    window.location = '/';
  }, []);
 
  const logUserIn = useCallback((user) => {
    setLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY, {
      authToken: user.authToken,
      id: user.id
    });
    removeLocalStorageEntry(ADMIN_USER_LOCALSTORAGE_KEY);
    setCurrentUser(user);
    setCurrentAdminUser(null);
  }, []);


  const logUserOut = useCallback(() => {
    setCurrentUser(null);
    removeLocalStorageEntry(DOCTOR_USER_LOCALSTORAGE_KEY);
    window.location = '/';
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
        currentAdminUser,
        logAdminUserIn,
        logAdminUserOut,
        logUserIn,
        logUserOut,
        adminLoading,
        doctorLoading
        // refetch
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};


const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('AuthWrapper is missing');
  }
  return context;
};

export const AdminAuthenticatedRoute = ({ children }) => {
  const { currentAdminUser, adminLoading } = useAuthentication();

  if (!adminLoading && !currentAdminUser) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  if (adminLoading) {
    return null
  }

  return children;
};

export const DoctorAuthenticatedRoute = ({ children }) => {
  const { currentUser, doctorLoading } = useAuthentication();

  if (!doctorLoading && !currentUser) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  if (doctorLoading) {
    return null
  }

  return children;
};

export const AnalyticsAuthenticatedRoute = ({ children }) => {
  const { currentUser, doctorLoading } = useAuthentication();

  if ((!doctorLoading && !currentUser)) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  
  if (doctorLoading) {
    return null
  }

  if (currentUser?.hasAnalytics) {
    return children;
  } else {
    // user is not authenticated
    return <Navigate to="/" />;
  }
}

export { AuthenticationContext, useAuthentication };
export default AuthWrapper;