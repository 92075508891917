import { gql } from "@apollo/client"


export const PROOFREAD_TRANSCRIPTION_MUTATION = gql`
  mutation ProofreadTranscription(
    $transcriptionId: ID!
    $proofreadContent: String!
    $regenerateSoap: Boolean!
  ) {
    proofreadTranscription(
      transcriptionId: $transcriptionId
      proofreadContent: $proofreadContent
      regenerateSoap: $regenerateSoap
    ) {
      id
    }
  }
`
