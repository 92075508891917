import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { getLocalStorageEntry } from 'utils/local-storage'
import { onError } from "@apollo/client/link/error";
const { REACT_APP_GRAPHQL_URL } = process.env;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
})

const authLink = new ApolloLink((operation, forward) => {
    const adminUser = getLocalStorageEntry('_transcription_admin_user');
    const doctorUser = getLocalStorageEntry('_transcription_doctor_user');
    const hospital = getLocalStorageEntry('_transcription_hospital');

    operation.setContext(({ headers }) => ({
        headers: {
            'X-ADMIN-AUTH-TOKEN': adminUser?.authToken,
            'X-USER-AUTH-TOKEN': doctorUser?.authToken,
            'X-Hospital-ID': hospital?.id,
            // authorization: `Bearer ${adminUser?.authToken}`,
            ...headers
        }
    }));
    return forward(operation);
});

const httpLink = new HttpLink({
    uri: REACT_APP_GRAPHQL_URL,
    credentials: 'omit',
});

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
});

export default client;