import PatchIconLogoWhite from '../../assets/patch-icon-logo-white.png'
import { Link } from 'react-router-dom'

const Home = () => {

    return (
        <div className='h-screen w-full bg-slate-800'>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img className="mx-auto h-16 w-auto" src={PatchIconLogoWhite} alt="Patch" />
                    <Link to='/doctor/login'><p className="mt-6 text-center leading-9 tracking-tight text-slate-100">Doctor/Manager Login</p></Link>
                    <Link to='/admin/login'><p className="mt-6 text-center leading-9 tracking-tight text-slate-100">Patch Admin Login</p></Link>
                </div>
            </div>
        </div>
    )

}

export default Home