import { gql } from "@apollo/client"

export const CREATE_TELEMEDICINE_CALL_MUTATION = gql`
  mutation CreateTelemedicineCall(
    $temporaryName: String!
    $userId: ID!
    $appointmentDatetime: String!
    $appointmentId: ID
  ) {
    createTelemedicineCall(
      temporaryName: $temporaryName
      userId: $userId
      appointmentDatetime: $appointmentDatetime
      appointmentId: $appointmentId
    ) {
      id
    }
  }
`

export const GET_DOCTOR_USERS_QUERY = gql`
  query GetDoctorUsers {
    doctorUsers {
      id
      emailSignature
    }
  }
`

export const GET_TODAY_APPOINTMENTS_QUERY = gql`
  query GetAppointments {
    todayAppointments {
      id
      kind
      friendlyDate
      friendlyTime
      scheduledAt
      status
      patientName
    }
  }
`;

export const GET_WEEKLY_APPOINTMENTS_QUERY = gql`
  query GetAppointments {
    weeklyAppointments {
      id
      kind
      friendlyDate
      friendlyTime
      scheduledAt
      status
      patientName
    }
  }
`;
