import { gql } from "@apollo/client";

export const GET_CURRENT_ADMIN_USER_QUERY = gql`
  query GetCurrentAdminUser {
    currentAdminUser {
      firstName
      lastName
      fullName
      photoUrl
      email
      id
    }
  }
`;

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      firstName
      lastName
      fullName
      photoUrl
      email
      id
      hasAnalytics
    }
  }
`;