import { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import { ADMIN_CREATE_HOSPITAL_MUTATION } from './queries'

const NewHospitalModal = ({ refetch, open, accountId }) => {

  const [name, setName] = useState(null)
  const [timezone, setTimezone] = useState("Pacific Time (US & Canada)")
  const [website, setWebsite] = useState(null)
  const [address1, setAddress1] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [zip, setZip] = useState(null)

  const [isVisible, setIsVisible] = useState(false)

  console.log('open', open)
  console.log('isVisible', isVisible)

  useEffect(() => {
    setIsVisible(open)
  }, [open])

  const [createHospital, _] = useMutation(ADMIN_CREATE_HOSPITAL_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      refetch()
      setName(null)
      setTimezone("Pacific Time (US & Canada)")
      setWebsite(null)
      setAddress1(null)
      setCity(null)
      setState(null)
      setZip(null)
      setIsVisible(false)
    },
    variables: {
      accountId,
      name,
      timezone,
      website,
      address1,
      city,
      state,
      zip,
    }
  })

  if (!isVisible) return null;

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="flex flex-col sm:items-start w-full">
              <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left mb-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">New Hospital</h3>
              </div>
              <div className="flex flex-col px-4 w-full">
                <div className="mt-4">
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                  <div>
                    <input value={name} onChange={(e) => setName(e.target.value)} id="name" name="name" placeholder="Pasadena Pet Hospital" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-gray-900">Timezone</label>
                  <div>
                    <select value={timezone} onChange={(e) => setTimezone(e.target.value)} id="timezone" name="timezone" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6">
                      <option value="Pacific Time (US & Canada)">Pacific Time (US & Canada)</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Mountain Time (US & Canada)">Mountain Time (US & Canada)</option>
                      <option value="Central Time (US & Canada)">Central Time (US & Canada)</option>
                      <option value="Eastern Time (US & Canada)">Eastern Time (US & Canada)</option>
                    </select>
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">Website</label>
                  <div>
                    <input value={website} onChange={(e) => setWebsite(e.target.value)} id="website" name="website" placeholder="website.com" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="address1" className="block text-sm font-medium leading-6 text-gray-900">Address</label>
                  <div>
                    <input value={address1} onChange={(e) => setAddress1(e.target.value)} id="address1" placeholder="123 Main St" name="address1" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">City</label>
                  <div>
                    <input value={city} onChange={(e) => setCity(e.target.value)} id="city" name="city" placeholder="Pasadena" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">State</label>
                  <div>
                    <input value={state} onChange={(e) => setState(e.target.value)} id="state" name="state" placeholder="CA" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="zip" className="block text-sm font-medium leading-6 text-gray-900">Zip</label>
                  <div>
                    <input value={zip} onChange={(e) => setZip(e.target.value)} id="zip" name="zip" placeholder="93293" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 mt-20 sm:flex sm:pl-4">
              <button onClick={createHospital} type="button" className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto">Submit</button>
              <button onClick={() => setIsVisible(false)} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewHospitalModal