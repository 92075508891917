import { gql } from "@apollo/client"

export const LOGIN_ADMIN_USER_MUTATION = gql`
  mutation LoginAdminUser(
    $email: String!
    $password: String!
    $rememberMe: Boolean!
  ) {
    loginAdminUser(
      email: $email
      password: $password
      rememberMe: $rememberMe
    ) {
      id
      authToken
    }
  }
`
