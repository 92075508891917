const StatusBadge = ({ status }) => {

  if (status == 'completed' || status == 'Completed') {
    return (
      <div className='flex flex-row'>
        {/* <span className='h-10'>&nbsp;</span> */}
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize">{status}</span>
      </div>
    )
  }
  if (status == 'Scheduled' || status == 'scheduled') {
    return (
      <div className='flex flex-row'>
        {/* <span className='h-10'>&nbsp;</span> */}
        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 capitalize">{status}</span>
      </div>
    )
  }
  if (status == 'Setting Up') {
    return (
      <div className='flex flex-row'>
        {/* <span className='h-10'>&nbsp;</span> */}
        <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20 capitalize">{status}</span>
      </div>
    )
  }
  if (status == 'In Progress') {
    return (
      <div className='flex flex-row'>
        {/* <span className='h-10'>&nbsp;</span> */}
        <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 capitalize">In Progress</span>
      </div>
    )
  }
  
  return null;

}

export default StatusBadge