import MainLayout from 'components/doctor/main-layout'
import { useState, useEffect } from 'react'
import { GET_TELEMEDICINE_CALLS_LIST } from './queries'
import { useQuery } from '@apollo/client'
import List from './list'
import Pagination from './pagination'
import { useSearchParams } from 'react-router-dom'
import { useHospitals } from 'contexts/hospital'
import NewTelemedicineCallModal from './new-telemedicine-call-modal'
import Pusher from 'pusher-js'

const PAGE_SIZE = 20

const TelemedicineListPage = () => {
  const { currentHospital } = useHospitals()
  const [searchParams, _] = useSearchParams();
  const [filter, setFilter] = useState('All')
  const [modalOpen, setModalOpen] = useState(false)


  const offset = parseInt(searchParams.get('page')) * PAGE_SIZE || 0

  const { loading, error, data, refetch } = useQuery(GET_TELEMEDICINE_CALLS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: filter,
      limit: PAGE_SIZE,
      offset: offset
    }
  });

  console.log('telemed calls', data)

  useEffect(() => {
    const pusher = new Pusher('d10606873286053e4a22', {
      cluster: 'us3',
    });

    const channel = pusher.subscribe(`telemedicinecall-list`)

    channel.bind('updated', function (data) {
      refetch()
    });

    return () => channel.unbind('updated')
  }, [])

  console.log('telemedicineCalls', data)

  const toggleFilter = () => {
    if (filter === 'All') {
      setFilter('Mine')
    }

    if (filter === 'Mine') {
      setFilter('All')
    }
  }


  if (loading) return null;
  if (error) return <span>Error : {error.message}</span>;

  return (
    <MainLayout>
      <div className='flex flex-row h-100 w-full' style={{ 'height': 'calc(100vh - 4rem)' }}>
        <div className='flex flex-col w-full h-screen border-l overflow-y-scroll'>
          <div className='w-full py-8 px-8'>
            <div className='flex flex-row items-center justify-between'>
              <h1 className='font-bold text-lg'>Telemedicine Calls</h1>
              <button onClick={() => setModalOpen(true)} className='rounded-md px-4 py-2 bg-blue-500 text-white font-medium'>Add Call</button>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input checked={filter === 'Mine'} onChange={toggleFilter} id="mine" aria-describedby="mine-description" name="mine" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="mine" className="font-medium text-gray-900">Show Only Mine</label>
              </div>
            </div>
            <div className="inline-block min-w-full py-2 align-middle">
              <List telemedicineCalls={data?.telemedicineCalls} />
            </div>
          </div>
          {data.telemedicinePages > 1 && <Pagination pages={data.telemedicinePages} />}
        </div>
      </div>
      <NewTelemedicineCallModal open={modalOpen} setIsOpen={setModalOpen} refetch={refetch} />
    </MainLayout>
  )

}

export default TelemedicineListPage