import { Link, useLocation } from 'react-router-dom'
import { InboxIcon, ActiveInboxIcon, SentIcon, ActiveSentIcon, DraftIcon, ActiveDraftIcon, AllIcon, ActiveAllIcon, SpamIcon, ActiveSpamIcon, TrashIcon, ActiveTrashIcon } from './icons'

const EmailLeftBar = () => {
  const { pathname } = useLocation()
  return (
    <div className='inset-x-0 w-48 max-w-72 py-8 px-4 h-full bg-slate-100'>
      <Link to='/email/inbox'><p className={`${pathname == '/email/inbox' ? 'font-bold bg-slate-200 border border-slate-300' : 'font-normal'} px-4 hover:bg-slate-200 hover:border-slate-300 py-1 text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-1 rounded-md`}>{pathname == '/email/inbox' ? <ActiveInboxIcon /> : <InboxIcon />}Inbox</p></Link>
      <Link to='/email/sent'><p className={`${pathname == '/email/sent' ? 'font-bold bg-slate-200 border border-slate-300' : 'font-normal'} px-4 hover:bg-slate-200 hover:border-slate-300 py-1 text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-1 rounded-md`}>{pathname == '/email/sent' ? <ActiveSentIcon /> : <SentIcon />}Sent</p></Link>
      <Link to='/email/drafts'><p className={`${pathname == '/email/drafts' ? 'font-bold bg-slate-200 border border-slate-300' : 'font-normal'} px-4 hover:bg-slate-200 hover:border-slate-300 py-1 text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-1 rounded-md`}>{pathname == '/email/drafts' ? <ActiveDraftIcon /> : <DraftIcon />}Drafts</p></Link>
      <Link to='/email/all'><p className={`${pathname == '/email/all' ? 'font-bold bg-slate-200 border border-slate-300' : 'font-normal'} px-4 hover:bg-slate-200 hover:border-slate-300 py-1 text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-1 rounded-md`}>{pathname == '/email/all' ? <ActiveAllIcon /> : <AllIcon />}All Mail</p></Link>
      <Link to='/email/spam'><p className={`${pathname == '/email/spam' ? 'font-bold bg-slate-200 border border-slate-300' : 'font-normal'} px-4 hover:bg-slate-200 hover:border-slate-300 py-1 text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-1 rounded-md`}>{pathname == '/email/spam' ? <ActiveSpamIcon /> : <SpamIcon />}Spam</p></Link>
      <Link to='/email/trash'><p className={`${pathname == '/email/trash' ? 'font-bold bg-slate-200 border border-slate-300' : 'font-normal'} px-4 hover:bg-slate-200 hover:border-slate-300 py-1 text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-1 rounded-md`}>{pathname == '/email/trash' ? <ActiveTrashIcon /> : <TrashIcon />}Trash</p></Link>
    </div>
  )
}

export default EmailLeftBar