import { gql } from "@apollo/client"


export const GET_TRANSCRIPTION_QUERY = gql`
  query GetTranscription($transcriptionId: ID!) {
    transcription(transcriptionId: $transcriptionId) {
      id
      filename
      status
      uploadedAt
      updatedAt
      transcribedContent
      revisedContent
      soapContent
      proofreadContent
      clientEmailContent
      temporaryName
      friendlyName
      patientName
      hospitalName
      userFullName
      friendlyUploadedAt
      integrationStatus
      soapNotYetWrittenToPims
      integrationSoapWriteable
      integrationSoapRewriteable

      hospital {
        integrationEnabled
        integrationProvider
        integrationSoapWriteable
        integrationSoapRewriteable
      }
    }
  }
`;


// friendlyAppointmentScheduledAt
// patientName