import { useState, useEffect } from 'react';
import MainLayout from 'components/admin/main-layout';
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { GET_ADMIN_ACCOUNT_QUERY } from './queries'
import StatusBadge from 'components/admin-account-status-badge';
import HospitalsTab from './hospitals-tab';
import UsersTab from './users-tab';
import BillingTab from './billing-tab';

const AdminAccountPage = () => {

  const [currentTab, setCurrentTab] = useState('hospitals')

  const { accountId } = useParams()

  const { data, refetch } = useQuery(GET_ADMIN_ACCOUNT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountId
    }
  })

  
  if (!data) { return null }


  return (
    <MainLayout>
      <div className='p-8'>
        <Link to='/admin/accounts' className='text-xs text-indigo-500 hover:text-indigo-600'>&larr; Back</Link>
        <div className='flex flex-row items-end justify-start'>
          {/* <h1 className='text-xl font-bold mr-4'>Appointment with "{data.adminAccount.patientName}"</h1> */}
          <h1 className='text-xl font-bold mr-4'>{data.adminAccount.name}</h1>
          <div><StatusBadge status={data.adminAccount.status} /></div>
        </div>
        <h3 className='text-medium font-medium text-slate-500'>{data.adminAccount.friendlyCustomerSince ? <span>Customer Since {data.adminAccount.friendlyCustomerSince}</span> : <span>Created {data.adminAccount.friendlyCreatedAt}</span>}</h3>
        {/* <h3 className='text-medium font-medium text-slate-500'>{data.adminAccount.friendlyAppointmentScheduledAt}</h3> */}
        <br />
        <p className='text-slate-600'>
          <span className='font-medium text-slate-800'>MRR</span>: ${data.adminAccount.mrr}</p>
        <br />
        <div>
          <div className="sm:block">
            <div className="border-b border-slate-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {/* <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700" --> */}
                <a onClick={() => setCurrentTab('hospitals')} className={`${currentTab == 'hospitals' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Hospitals</a>
                <a onClick={() => setCurrentTab('users')} className={`${currentTab == 'users' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Users</a>
                <a onClick={() => setCurrentTab('billing')} className={`${currentTab == 'billing' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Billing</a>
              </nav>
            </div>
          </div>
        </div>
        <div className='py-8'>
          {currentTab == 'hospitals' && <HospitalsTab account={data.adminAccount} refetch={refetch} />}
          {currentTab == 'users' && <UsersTab account={data.adminAccount} refetch={refetch} />}
          {currentTab == 'billing' && <BillingTab account={data.adminAccount} refetch={refetch} />}
        </div>
      </div>
    </MainLayout>
  )
}

export default AdminAccountPage