import { gql } from "@apollo/client"

export const COMPLETE_TRANSCRIPTION_MUTATION = gql`
  mutation CompleteTranscription(
    $transcriptionId: ID!
  ) {
    completeTranscription(
      transcriptionId: $transcriptionId
    ) {
      id
    }
  }
`

// write to pims is only not required during rollout to not break

export const SAVE_SOAP_MUTATION = gql`
  mutation SaveSoap(
    $transcriptionId: ID!
    $soapContent: String!
    $regenerateEmail: Boolean!
    $writeToPims: Boolean
  ) {
    saveSoap(
      transcriptionId: $transcriptionId
      soapContent: $soapContent
      regenerateEmail: $regenerateEmail
      writeToPims: $writeToPims
    ) {
      id
    }
  }
`
