import { gql } from "@apollo/client"


export const GET_CLIENT_CONTEXT_QUERY = gql`
  query ClientContext($clientEmail: String!) {
    clientContext(clientEmail: $clientEmail) {
      id
      vetdataFirstName
      vetdataLastName
      vetdataSignificantOther
      vetdataDeleted
      vetdataInactive
      friendlyVetdataFirstVisitDate

      clientBalanceForHospital {
        id
        vetdataAgedCurrent
      }

      emails {
        id
        vetdataAddress
        vetdataIsPrimary
        vetdataType
      }
      phones {
        id
        formattedVetdataNumber
        vetdataIsPrimary
        vetdataType
      }

      clientAlerts {
        id
        vetdataValue
      }
      clientNotes {
        id
        friendlyVetdataDate
        vetdataValue
      }
      reminders {
        id
        friendlyVetdataDateDue
        vetdataCode
        vetdataDescription
      }

      patients {
        id
        vetdataName
        vetdataAge
        vetdataSpeciesDescription
        vetdataSpeciesBreedDescription
        vetdataSpeciesColorDescription
        vetdataSpeciesGenderDescription
        friendlyVetdataDateOfDeath
        vetdataDeleted
        vetdataInactive
        vetdataIsDeceased
      }
    }
  }
`;
export const GET_PATIENT_CONTEXT_QUERY = gql`
  query PatientContext($patientId: ID!) {
    patientContext(patientId: $patientId) {
      id
      vetdataName
      vetdataAge
      vetdataSpeciesDescription
      vetdataSpeciesBreedDescription
      vetdataSpeciesColorDescription
      vetdataSpeciesGenderDescription
      friendlyVetdataDateOfDeath
      friendlyVetdataDateOfBirth
      vetdataCurrentWeight
      vetdataCurrentWeightUnit
      vetdataDeleted
      vetdataInactive
      vetdataIsDeceased

      clients {
        id
        vetdataFirstName
        vetdataLastName
      }

      patientAlerts {
        id
        friendlyVetdataDate
        vetdataValue
      }
      
      patientNotes {
        id
        friendlyVetdataDate
        vetdataValue
      }
      
      vaccinations {
        id
        friendlyVetdataDateGiven
        friendlyVetdataExpirationDate
        vetdataDescription
      }
      prescriptions {
        id
        friendlyVetdataIssuedDate
        vetdataDescription
        vetdataQuantity
      }
    }
  }
`;
