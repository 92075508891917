import { useLazyQuery, useQuery } from "@apollo/client";
import MainLayout from "components/doctor/main-layout";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GET_ANALYTICS, GET_ANALYTICS_FILTERS } from "./queries";
import { useSearchParams } from 'react-router-dom';

/* Insights Page */
/* This page is used to display insights for the user */

const Insights = () => {
  return (
    <MainLayout>
      <div
        className="flex flex-row h-100 w-full"
        style={{ height: "calc(100vh - 4rem)", backgroundColor: "#F7F7F7" }}
      >
        <Content />
      </div>
    </MainLayout>
  );
};

export default Insights;

const Content = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [timePeriodFilter, setTimePeriodFilter] = useState([]);
  const [hospitalFilter, setHospitalFilter] = useState([]);
  const [doctorFilter, setDoctorFilter] = useState([]);

  const [currentFilters, setCurrentFilters] = useState({
    hospitalId: null,
    timePeriod: null,
    doctorId: null,
  });

  const [query, { loading, data, called }] = useLazyQuery(
    GET_ANALYTICS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        updateUrlParams('autorun', true)
      }
    }
  );

  const updateUrlParams = (key, value) => {
    if (!searchParams.has(key)) {
      searchParams.append(key, value);
      setSearchParams(searchParams);
    } else {
      if (value === null) {
        searchParams.delete(key)
        setSearchParams(searchParams);
      } else {
        searchParams.set(key, value)
        setSearchParams(searchParams);
      }
    }
  };

  const {
    data: filterData,
    refetch: filterRefetch,
  } = useQuery(GET_ANALYTICS_FILTERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      hospitalId: null,
    },
  });
  
  useEffect(() => {
    console.debug(data);
  }, [data, filterData]);

  useEffect(() => {
    if (filterData) {
      setTimePeriodFilter(filterData.analyticsFilters.timePeriods);
      setHospitalFilter(filterData.analyticsFilters.hospitals);
      setDoctorFilter(filterData.analyticsFilters.doctors);

      setCurrentFilters({
        hospitalId: filterData.analyticsFilters.hospitals.find(h => h.id === searchParams.get('hospitalId'))?.id || null,
        timePeriod: filterData.analyticsFilters.timePeriods.find(t => t === searchParams.get('timePeriod')) || null,
        doctorId: filterData.analyticsFilters.doctors.find(d => d.id === searchParams.get('doctorId'))?.id || null,
      })

      if (searchParams.get('autorun') === 'true') {
        runReport();
      }
    }
  }, [filterData, searchParams]);

  useEffect(() => {
    // if the hospital filter changes we need to refetch the filters for the doctors
    filterRefetch({
      timePeriod: currentFilters.timePeriod,
      hospitalId:
        currentFilters.hospitalId == "All" ? null : currentFilters.hospitalId,
    });
    // this will then refetch the query for the data as well
  }, [currentFilters.hospitalId]);

  const runReport = useCallback(() => {
    // if the hospital filter changes we need to refetch the filters for the doctors
    // if the filters change we requery the data
    setSearchFilter("")

    query({
      variables: {
        hospitalId:
          currentFilters.hospitalId == "All" ? null : currentFilters.hospitalId,
        timePeriod:
          currentFilters.timePeriod == "All" ? null : currentFilters.timePeriod,
        doctorId:
          currentFilters.doctorId == "All" ? null : currentFilters.doctorId,
      },
    });
  }, [
    currentFilters.hospitalId,
    currentFilters.doctorId,
    currentFilters.timePeriod,
  ]);

  const doctorIdRef = useRef(null);

  const setFilter = (evt) => {
    updateUrlParams('autorun', false)
    updateUrlParams(evt.target.name, (evt.target.value.length && evt.target.value != 'All') ? evt.target.value : null)

    // if the filter is the hospital filter we also reset the doctor filter
    if (evt.target.name === "hospitalId") {
      setCurrentFilters({
        ...currentFilters,
        [evt.target.name]: evt.target.value.length ? evt.target.value : null,
        doctorId: null,
      });
    } else {
      setCurrentFilters({
        ...currentFilters,
        [evt.target.name]: evt.target.value.length ? evt.target.value : null,
      });
    }
  };

  return (
    <div className="flex flex-col w-full h-screen border-l overflow-y-scroll">
      {/* header */}
      <div className="flex flex-row items-center justify-between w-full px-8 py-6">
        <h1 className="text-2xl font-semibold">Insights</h1>
      </div>
      {/* Filters - time period - hospital - doctors */}
      <div className="flex flex-row items-center content-end w-full px-8 py-4 gap-x-5">
        <div className="flex flex-col">
          <p className="text-sm font-bold text-gray-500">Time Period</p>
          <select
            name="timePeriod"
            className="border border-gray-300 rounded-md px-4 py-2 w-64"
            onChange={setFilter}
            value={currentFilters.timePeriod}
          >
            <option name="timePeriod" key={"All"} value={null}>
              All
            </option>
            {timePeriodFilter.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <p className="text-sm font-bold text-gray-500">Hospital</p>
          <select
            name="hospitalId"
            className="border border-gray-300 rounded-md px-4 py-2 w-64"
            onChange={setFilter}
            value={currentFilters.hospitalId}
          >
            <option name="hospitalId" key={"All"} value={null}>
              All
            </option>
            {hospitalFilter.map((item) => (
              <option name="hospitalId" key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <p className="text-sm font-bold text-gray-500">Doctors</p>
          <select
            ref={doctorIdRef}
            name="doctorId"
            className="border border-gray-300 rounded-md px-4 py-2 w-64"
            onChange={setFilter}
            value={currentFilters.doctorId}
          >
            <option
              name="doctorId"
              key={"All"}
              value={null}
              label="All"
            ></option>
            {doctorFilter.map((item) => (
              <option name="doctorId" key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <br />
          <button
            onClick={runReport}
            type="button"
            className={`rounded-md ${loading ? "bg-gray-500 cursor-not-allowed" : "bg-gray-800"} px-3 py-3 text-sm font-semibold text-white hover:text-gray-100`}
            disabled={loading}
          >
            {loading ? "Running Insights Report" : "Run Insights Report"}
          </button>
        </div>
      </div>
      <List
        items={data?.analytics.problems || []}
        refetch={query}
        loading={loading}
        called={called}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
    </div>
  );
};

const SearchBar = ({ loading, searchFilter, setSearchFilter }) => {
  const search = (evt) => {
    evt.preventDefault();
    setSearchFilter(evt.target.value);
  };

  return (
    <div className="pt-2 flex-start relative text-gray-600 w-64">
      <input
        className={`border-2 border-gray-300 ${loading ? 'bg-gray-200': 'bg-white'} h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none`}
        type="search"
        name="search"
        placeholder="Search"
        onChange={search}
        disabled={loading}
        value={searchFilter}
      />
      <button className="absolute right-0 top-0 mt-5 mr-8">
        <svg
          className="text-gray-600 h-4 w-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 56.966 56.966"
          style={{ enableBackground: "new 0 0 56.966 56.966" }}
          xmlSpace="preserve"
          width="512px"
          height="512px"
        >
          <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
        </svg>
      </button>
    </div>
  );
};

/* List of items */
const List = ({ items, loading, called, searchFilter, setSearchFilter }) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [showAll, setShowAll] = useState(true);
  const [expandAll, setExpandAll] = useState(null); // null because not explicitly set and we want to default-collapse empties

  useEffect(() => {
    if (showAll) {
      setFilteredItems(items);
    } else {
      setFilteredItems(items.filter((item) => item.occurrences > 0));
    }
  }, [items, showAll]);

  const rows = useMemo(() => {
    if (!filteredItems.length) return [];

    const localItems = filteredItems.filter((item) => {
      return (
        item?.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item?.recommendations.some((recommendation) =>
          recommendation.name.toLowerCase().includes(searchFilter.toLowerCase())
        )
      );
    });

    return localItems.map((item, index) => {
      return (
        <Row
          key={index}
          index={index + 1}
          item={item}
          searchFilter={searchFilter}
          expandAll={expandAll}
        />
      );
    });
  }, [filteredItems, searchFilter, expandAll]);

  return (
    <div className="w-full px-8">
      {!called && (
        <div className="text-center py-4">
          <h2 className="text-2xl font-semibold">
            Run a report to discover insights
          </h2>
          <p className="text-gray-500">Use the filters above to run a report</p>
          <b className="text-gray-500">
            Note: A report may take up to 1 minute to run
          </b>
        </div>
      )}
      {!!called && (
        <>
          <div className="pt-2 relative mx-auto text-gray-600 flex justify-between">
            <SearchBar
              setSearchFilter={setSearchFilter}
              searchFilter={searchFilter}
              loading={loading}
            />
            <div className="flex justify-around gap-x-5">
              {expandAll === null && (<button
                onClick={() => setExpandAll(false)}
                type="button"
                className="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white hover:text-gray-100"
              >
              Collapse All
              </button>)}

              {/* null hasn't been set yet, always move to collapse first */}
              {expandAll !== null && (<button
                onClick={() => setExpandAll((prev) => !prev)}
                type="button"
                className="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white hover:text-gray-100"
              >
                {expandAll ? "Collapse All" : "Expand All"}
              </button>)}

              <button
                onClick={() => setShowAll((prev) => !prev)}
                type="button"
                className="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white hover:text-gray-100"
              >
                {showAll ? "Hide Empty" : "Show All Scenarios"}
              </button>
            </div>
          </div>
          <div className="inline-block min-w-full py-2 align-middle">
            {/* if the query has not been called yet */}
            {/* if the query has been called but there are no results */}
            <table
              className={`${
                loading && "opacity-40"
              } min-w-full divide-y divide-gray-300 bg-white px-4`}
            >
              <thead>
                <tr>
                  <th className="py-3.5 text-left text-sm font-semibold text-gray-900 pl-2">
                    #
                  </th>
                  <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                    Scenario
                  </th>
                  <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                    Occurrences
                  </th>
                  <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                    % of Appointments
                  </th>
                  {/* <th className="py-3.5 text-left text-sm font-semibold text-gray-900">
                    Rejected %
                  </th> */}
                </tr>
              </thead>
              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan="5" className="text-center py-4">
                      Loading... (May take up to 1 minute)
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {rows}
                </tbody>
              )}
            </table>
          </div>
        </>
      )}
    </div>
  );
};

const Row = ({ index, item, searchFilter, expandAll }) => {
  const { name, percent, occurrences } = item;
  const [expandLocal, setExpandLocal] = useState(true);

  useEffect(() => {

    // only force expand all except on inital load
    if (searchFilter !== "" && expandAll !== false && occurrences > 0) {
      setExpandLocal(true)
    } else if (expandAll !== null) {
      setExpandLocal(expandAll);
    } else {
      setExpandLocal(occurrences > 0);
    }

    
  }, [expandAll, searchFilter]);

  const highlightSearchedText = (text) => {
    if (!searchFilter) return text;

    const regex = new RegExp(`(${searchFilter})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === searchFilter.toLowerCase() ? (
        <span className='underline font-bold' key={index}>{part}</span>
      ) : (
        part
      )
    );
  };

  return (
    <>
      {/* problem */}
      <tr
        key={`row-${index}`}
        className="cursor-pointer hover:bg-gray-100"
        onClick={() => setExpandLocal((prev) => !prev)}
      >
        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div>
              <div className="font-medium text-gray-900 pl-2">{index}</div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div>
              <div className="font-medium text-gray-900">{highlightSearchedText(name)}</div>
            </div>
          </div>
        </td>
        <td
          className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0"
        >
          <div className="flex items-center">
            <div>
              <div className="font-medium text-gray-900">{occurrences > 0 ? occurrences : '-'}</div>
            </div>
          </div>
        </td>
        <td
          className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0"
        >
          <div className="flex items-center">
            <div>
              <div className="font-medium text-gray-900">{percent ? `${percent}%` : "-"}</div>
            </div>
          </div>
        </td>
      </tr>
      {/* recommendations */}
      {expandLocal &&
        item.recommendations.map((recommendation, i) => (
          <tr
            key={`recommendation-${i}-${index}`}
            className="cursor-pointer bg-purple-50 hover:bg-purple-100"
            onClick={() => {}}
          >
            <td className="">
              <div className="flex items-center text-xs">
                <div>
                  <div className="font-medium text-gray-900"></div>
                </div>
              </div>
            </td>
            <td>
              <div className="flex items-center">
                <div>
                  <div className="font-medium text-gray-900 text-sm">
                    <ul style={{ listStyle: "circle" }}>
                      <li>{highlightSearchedText(recommendation.name)}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
            <td
              className="whitespace-nowrap py-1 pl-4 pr-3 text-xs sm:pl-0"
            >
              <div className="flex items-center">
                <div>
                  {/* Only add a border around the real values and if it's not occurences */}
                  <div
                    className="font-bold text-gray-900 rounded-md py-1"
                  >
                    {recommendation.occurrences > 0 ? recommendation.occurrences : '-'}
                  </div>
                </div>
              </div>
            </td>
            <td
              className="whitespace-nowrap py-1 pl-4 pr-3 text-xs sm:pl-0"
            >
              <div className="flex items-center">
                <div>
                  <div
                    className="font-bold text-gray-900 rounded-md py-1"
                  >
                    {recommendation.percent ? `${recommendation.percent}%` : "-"}
                    {/* of appts with ${name} */}
                  </div>
                </div>
              </div>
            </td>
            {/* <td
              className="whitespace-nowrap py-1 pl-4 pr-3 text-xs sm:pl-0"
            >
              <div className="flex items-center">
                <div>
                  <div
                    className="font-bold text-gray-900 rounded-md  py-1 px-1"
                  >
                    {recommendation.rejected ? `${recommendation.rejected}%` : "-"}
                  </div>
                </div>
              </div>
            </td> */}
          </tr>
        ))}
    </>
  );
};