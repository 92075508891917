import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import PatchLogo from './patch-logo.png'
import PatchIconWhiteLogo from './patch-icon-logo-white.png'
import { useAuthentication } from 'contexts/authentication'

const HeaderDesktop = () => {
    const { pathname } = useLocation()
    const { currentAdminUser, logAdminUserOut } = useAuthentication()

    const [showDropdownMenu, setShowDropdownMenu] = useState(false)


    return (
        <div className='bg-slate-800 h-16 flex flex-row items-center shadow-sm border-b border-b-slate-900'>
            <div className='flex flex-row justify-between items-center w-full px-4'>
                <div className='flex flex-row items-center justify-center pb-4 pt-2'>
                    <img src={PatchIconWhiteLogo} className='h-8 mt-2' />
                    <ul className="flex flex-row items-center justify-start">
                        <li><Link to='/admin/records' className={`${pathname.includes('/admin/records') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-white-500`}>Records</Link></li>
                        <li><Link to='/admin/accounts' className={`${pathname.includes('/admin/accounts') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-white-500`}>Accounts</Link></li>
                    </ul>
                </div>
                <div className='relative'>
                    <p className='text-white font-medium cursor-pointer' onClick={() => setShowDropdownMenu(prev => !prev)}>Hi, {currentAdminUser?.fullName}</p>
                    {showDropdownMenu && <div className="absolute right-0 z-10 mt-2 flex w-screen max-w-min">
                        <div className="w-56 shrink rounded-md bg-white p-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                            <a onClick={logAdminUserOut} className="cursor-pointer block p-2 hover:text-indigo-600">Logout</a>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default HeaderDesktop