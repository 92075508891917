import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { PROOFREAD_TRANSCRIPTION_MUTATION } from './queries'


const ContentTab = ({ transcription, refetch }) => {
  const [content, setContent] = useState()

  useEffect(() => {
    setContent(transcription.proofreadContent || transcription.revisedContent)
  }, [transcription])

  const [proofreadTranscription, _] = useMutation(PROOFREAD_TRANSCRIPTION_MUTATION, {
    errorPolicy: "all",
  })

  const onSave = () => {
    proofreadTranscription({
      variables: {
        transcriptionId: transcription.id,
        proofreadContent: content,
        regenerateSoap: false
      },
      onCompleted: () => {
        refetch()
        // setCurrentTranscription(null)
        // refetchList()
      }
    })
  }

  const onSaveAndRegenerate = () => {
    proofreadTranscription({
      variables: {
        transcriptionId: transcription.id,
        proofreadContent: content,
        regenerateSoap: true
      },
      onCompleted: () => {
        refetch()
        // setCurrentTranscription(null)
        // refetchList()
      }
    })
  }


  return (
    <div>
      <textarea
        className='w-full mb-4 rounded-lg border-gray-300'
        placeholder="Edit transcription here..."
        value={content}
        rows={15}
        onChange={(e) => setContent(e.target.value)}
      />
      <div>
        <button onClick={onSave} className='text-white bg-black px-4 py-2 rounded-md mr-2'>Save</button>
        <button onClick={onSaveAndRegenerate} className='text-white bg-black px-4 py-2 rounded-md mr-2'>Save + Regenerate SOAP</button>
      </div>
    </div>
  )


}

export default ContentTab