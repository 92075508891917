import { useState } from "react";
import { useQuery } from "@apollo/client"
import { GET_CLIENT_CONTEXT_QUERY } from './queries'
import Patient from './patient'

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ContextSidebar = ({ clientEmail }) => {

  const [context, setContext] = useState('client')

  const { data, loading, error, refetch } = useQuery(GET_CLIENT_CONTEXT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      clientEmail: clientEmail
    }
  })

  console.log('client email', clientEmail)
  console.log('client data', data)

  if (loading) { return null }
  if (!data && !loading) {
    return (
      <div className='border-l shadow-lg bg-white h-full py-4 px-4 h-full bg-white' style={{ width: '40rem' }}>
        <p className='text-slate-600 text-xs mb-4'>PIMS Information</p>
        <p className='text-slate-600 text-sm'>No information found</p>
      </div>
    )
  }
  if (context == 'client') {
    return (
      <div className='border-l shadow-lg bg-white h-full py-4 px-4 h-full bg-white' style={{ width: '40rem' }}>
        <p className='text-slate-600 text-xs mb-4'>PIMS Information</p>
        <p className='text-xl mb-4'>
          {data.clientContext.vetdataFirstName} {data.clientContext.vetdataLastName}
          {data.clientContext.vetdataInactive && <span className='text-gray-400'> (Inactive)</span>}
          {data.clientContext.vetdataDeleted && <span className='text-red-400'> (Deleted)</span>}
        </p>
        {data.clientContext.vetdataSignificantOther && <p className='text-sm'><span className='font-medium'>Significant Other:</span> {data.clientContext.vetdataSignificantOther}</p>}
        {data.clientContext.friendlyVetdataFirstVisitDate && <p className='text-sm'><span className='font-medium'>Client since:</span> {data.clientContext.friendlyVetdataFirstVisitDate}</p>}
        <div className='mt-4'>
          <p className='font-bold text-sm'>Balance</p>
          <p>{currencyFormatter.format(data.clientContext.clientBalanceForHospital.vetdataAgedCurrent)}</p>
        </div>
        <div className='mt-4'>
          <p className='font-bold text-sm'>Patients</p>
          {data.clientContext.patients.length == 0 && <p className='text-slate-600 text-sm'>No patients</p>}
          {data.clientContext.patients.map(patient => (
            <p onClick={() => setContext(patient.id)} className='cursor-pointer text-blue-400 text-sm'>{patient.vetdataName} ({patient.vetdataAge}yo {patient.vetdataSpeciesGenderDescription} {patient.vetdataSpeciesBreedDescription}) {patient.vetdataIsDeceased && <span>[Deceased {patient.friendlyVetdataDateOfDeath}]</span>} {patient.vetdataInactive && <span>[Inactive]</span>} {patient.vetdataDeleted && <span>[Deleted]</span>}</p>
          ))}
        </div>
        <div className='mt-4'>
          <p className='font-bold text-sm'>Contact</p>
          {data.clientContext.phones.map(phone => (
            <p className='text-sm'>{phone.formattedVetdataNumber} ({phone.vetdataType}) {phone.vetdataIsPrimary && (
              <svg className='h-3 w-3 inline text-slate-600 mb-0.5' data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clipRule="evenodd" fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"></path>
              </svg>
            )}</p>
          ))}
          {data.clientContext.emails.map(email => (
            <p className='text-sm'>{email.vetdataAddress} ({email.vetdataType}) {email.vetdataIsPrimary && (
              <svg className='h-3 w-3 inline text-slate-600 mb-0.5' data-slot="icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clipRule="evenodd" fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"></path>
              </svg>
            )}</p>
          ))}
        </div>

        <div className='mt-4'>
          <p className='font-bold text-sm'>Alerts</p>
          {data.clientContext.clientAlerts.length == 0 && <p className='text-slate-600 text-sm'>No alerts</p>}
          {data.clientContext.clientAlerts.map(alert => (
            <p className='text-sm'>{alert.vetdataValue}</p>
          ))}
        </div>
        <div className='mt-4'>
          <p className='font-bold text-sm'>Notes</p>
          {data.clientContext.clientNotes.length == 0 && <p className='text-slate-600 text-sm'>No notes</p>}
          {data.clientContext.clientNotes.map(note => (
            <p className='text-sm'>{note.friendlyVetdataDate}: {note.vetdataValue}</p>
          ))}
        </div>
        <div className='mt-4'>
          <p className='font-bold text-sm'>Reminders</p>
          {data.clientContext.reminders.length == 0 && <p className='text-slate-600 text-sm'>No reminders</p>}
          {data.clientContext.reminders.map(reminder => (
            <p className='text-sm'>{reminder.friendlyVetdataDateDue}: ({reminder.vetdataCode}) {reminder.vetdataDescription}</p>
          ))}
        </div>
      </div>
    )
  } else {
    const patient = data.clientContext.patients.find(p => p.id == context)
    
    return (
      <Patient patientId={patient.id} setContext={setContext} clientId={data.clientContext.id} />
    )
  }

}

export default ContextSidebar