import { useState, useEffect } from 'react';
import MainLayout from 'components/doctor/main-layout';
import { useParams } from "react-router-dom"
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { GET_TELEMEDICINE_CALL_QUERY } from './queries'
import Pusher from 'pusher-js'
import StatusBadge from 'components/telemedicine-call-status-badge';

const { REACT_APP_APP_URL } = process.env;

const TelemedicineCallPage = () => {
  // const [client, initClient] = useDyteClient();

  const [copied, setCopied] = useState(false)

  const { callId } = useParams()

  const { data, refetch } = useQuery(GET_TELEMEDICINE_CALL_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      callId: callId
    }
  })

  useEffect(() => {
    const pusher = new Pusher('d10606873286053e4a22', {
      cluster: 'us3',
    });

    const channel = pusher.subscribe(`telemedicinecall-${callId}`)

    channel.bind('updated', function (data) {
      refetch()
    });

    return () => channel.unbind('updated')
  }, [])

  const copyClientLinkToClipboard = () => {
    navigator.clipboard.writeText(`https://video.patchvet.com/${data.telemedicineCall.dyteId}`)
    setCopied(true)
  }


  if (!data) { return null }

  return (
    <MainLayout>
      <div className='p-8'>
        <Link to='/telemedicine' className='text-xs text-indigo-500 hover:text-indigo-600'>&larr; Back</Link>
        <div className='flex flex-row items-end justify-start'>
          {/* <h1 className='text-xl font-bold mr-4'>Appointment with "{data.transcription.patientName}"</h1> */}
          <h1 className='text-xl font-bold mr-4'>{data.telemedicineCall.friendlyName}</h1>
          <div><StatusBadge status={data.telemedicineCall.status} /></div>
        </div>
        <h3 className='text-medium font-medium text-slate-500'>{data.telemedicineCall.friendlyScheduledAt}</h3>
        {/* <h3 className='text-medium font-medium text-slate-500'>{data.transcription.friendlyAppointmentScheduledAt}</h3> */}
        <br />
        <p className='text-slate-600'>
          <span className='font-medium text-slate-800'>Hospital</span>: {data.telemedicineCall.hospitalName}</p>
        <p className='text-slate-600'>
          <span className='font-medium text-slate-800'>Scheduled By</span>: {data.telemedicineCall.scheduledByUserFullName}
        </p>
        <p className='text-slate-600'>
          <span className='font-medium text-slate-800'>Performed By</span>: {data.telemedicineCall.userFullName}
        </p>
        {data.telemedicineCall?.transcription?.id && (
          <p className='text-slate-600'>
            <Link to={`/records/${data.telemedicineCall.transcription.id}`} className='font-medium text-blue-400'>Link to Medical Note</Link>
          </p>
        )}
        <br />

        
        {data && !data.telemedicineCall.expired && <a href={`${REACT_APP_APP_URL}/video/${data.telemedicineCall.dyteDoctorToken}`} target='_blank' className='text-white px-4 py-2 rounded-md bg-blue-500' rel="noreferrer">Join Call As Doctor</a>}
        {data && data.telemedicineCall.expired && <p className='text-red-700'>Call is expired</p>}
        {data && data.telemedicineCall.clientIsOnCall && <p>Client is on the call</p>}

        {!data.telemedicineCall.expired && (
          <div className='mt-8'>
            <label htmlFor="clientLink" className="block text-sm font-medium leading-6 text-gray-900">Client Link</label>
            <div className="relative mt-2 rounded-md shadow-sm w-96">
              <input value={`https://video.patchvet.com/${data.telemedicineCall.dyteId}`} type="text" name="account-number" id="account-number" className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              <div onClick={copyClientLinkToClipboard} className="absolute inset-y-0 right-0 flex items-center pr-3 hover:text-gray-800 cursor-pointer">
                <svg className='h-5 w-5 text-gray-500' data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"></path>
                </svg>
              </div>
            </div>
            <p className='text-gray-700 text-sm'>Link will expire 24 hours after scheduled appointment time.</p>
            {copied && <p className='text-green-700 font-medium text-sm'>Copied!</p>}
          </div>
        )}
      </div>
    </MainLayout>
  )
}

export default TelemedicineCallPage