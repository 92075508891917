import { gql } from "@apollo/client"


export const GET_ADMIN_ACCOUNT_QUERY = gql`
  query GetAdminAccount($accountId: ID!) {
    adminAccount(accountId: $accountId) {
      id
      name
      userCount
      doctorCount
      hospitalCount
      mrr
      licenseCount
      hardwareCount
      status
      friendlyCustomerSince
      friendlyCreatedAt
      stripeSubscriptionId
      stripeCheckoutUrl
      stripeSubscriptionUrl

      hospitals {
        id
        name
        fullAddress
        timezone
        userCount
        doctorCount
        website
      }

      users {
        id
        firstName
        lastName
        phone
        password
        kind
        email
        hospitalCount
      }
        
    }
  }
`;

// patientName
// friendlyAppointmentScheduledAt