import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import PatchLogo from './patch-logo.png'
import PatchIconWhiteLogo from './patch-icon-logo-white.png'
import { useAuthentication } from 'contexts/authentication'
import { useHospitals } from 'contexts/hospital'

const HeaderDesktop = () => {
    const { pathname } = useLocation()
    const { currentHospital, hospitals, setHospital } = useHospitals()
    const { currentUser, logUserOut } = useAuthentication()
    const [showUserDropdownMenu, setShowUserDropdownMenu] = useState(false)
    const [showHospitalDropdownMenu, setShowHospitalDropdownMenu] = useState(false)

    console.log('HeaderDesktop user', currentUser)
    console.log('HeaderDesktop hospital', currentHospital, hospitals)

    const changeHospital = (hospital) => {
        setHospital(hospital)
    }
    return (
        <div className='bg-slate-800 h-16 flex flex-row items-center shadow-sm border-b border-b-slate-900'>
            <div className='flex flex-row justify-between items-center w-full px-4'>
                <div className='flex flex-row items-center justify-center pb-4 pt-2'>
                    <img src={PatchIconWhiteLogo} className='h-8 mt-2' />
                    <ul className="flex flex-row items-center justify-start">
                        <li><Link to='/records' className={`${pathname.includes('/records') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-white-500`}>Records</Link></li>
                        <li><Link to='/telemedicine' className={`${pathname.includes('/telemedicine') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-white-500`}>Telemedicine</Link></li>
                        {currentUser?.hasAnalytics ? <li><Link to='/insights' className={`${pathname.includes('/insights') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-white-500`}>Insights</Link></li> : null}
                    </ul>
                </div>
                <div className='flex flex-row justify-start items-center'>
                    {!window.location.toString().includes('insights') && (
                        <div className="relative inline-block text-left">
                            <div>
                                <button onClick={() => setShowHospitalDropdownMenu(prev => !prev)} type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white hover:text-gray-100" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    {currentHospital?.name}
                                    <svg className="-mr-1 h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            <div className={`${showHospitalDropdownMenu || 'hidden'} absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                <div className="py-1" role="none">
                                    {hospitals?.map(hospital => (
                                        // <option key={location.id} value={location.id}>{location.name}</option>
                                        <a
                                            href="#"
                                            key={hospital.id}
                                            className={`${hospital.id == currentHospital.id ? 'text-gray-900 font-medium' : 'text-gray-700'} block px-4 py-2 text-sm`}
                                            role="menuitem"
                                            tabIndex="-1"
                                            id={`hospital-${hospital.id}`}
                                            onClick={() => changeHospital(hospital)}
                                        >
                                            {hospital.name}
                                        </a>
                                    )
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='relative ml-4'>
                        <p className='text-white text-sm font-medium cursor-pointer flex flex-row' onClick={() => setShowUserDropdownMenu(prev => !prev)}>
                            <span className='mr-2'>{currentUser?.fullName}</span>
                            <svg className="-mr-1 h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                            </svg>  
                        </p>
                        {showUserDropdownMenu && <div className="absolute right-0 z-10 mt-2 flex w-screen max-w-min">
                            <div className="w-56 shrink rounded-md bg-white p-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                <a onClick={logUserOut} className="cursor-pointer block p-2 hover:text-indigo-600">Logout</a>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderDesktop