import { gql } from "@apollo/client";

export const GET_TRANSCRIPTION_LIST = gql`
  query GetTranscriptions($filter: String, $offset: Int, $limit: Int) {
    transcriptions(filter: $filter, offset: $offset, limit: $limit) {
        id
        filename
        status
        uploadedAt
        updatedAt
        transcribedContent
        revisedContent
        proofreadContent
        friendlyUploadedDate
        friendlyUploadedTime
        temporaryName
        friendlyName
        userFullName
        hospitalName
        accountName
    }

    transcriptionPages(filter: $filter, limit: $limit)
  }
`;


// friendlyAppointmentScheduledAt
// patientName