import { Link } from 'react-router-dom'
import PatchIconLogoWhite from 'assets/patch-icon-logo-white.png'

const CheckoutFailurePage = () => {

  return (
    <div className='h-screen w-full bg-slate-800'>
      <div className="flex min-h-full flex-col items-center justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-16 w-auto" src={PatchIconLogoWhite} alt="Patch Education" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 flex flex-col items-center justify-center">
            <svg className="h-40 w-40 text-red-500" data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
            </svg>
            <p className='font-bold text-xl'>Something went wrong</p>
          </div>
        </div>
        <p className='text-white font-medium mt-8'><Link to="/doctor/login">Sign in</Link></p>
      </div>
    </div>
  )

}

export default CheckoutFailurePage