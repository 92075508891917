import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from 'pages/home';

import CheckoutSuccessPage from 'pages/checkout_success';
import CheckoutFailurePage from 'pages/checkout_failure';

import AdminLoginPage from 'pages/admin/login';
import AdminTranscriptionsPage from 'pages/admin/transcriptions';
import AdminTranscriptionPage from 'pages/admin/transcription'

import AdminAccountsPage from 'pages/admin/accounts';
import AdminAccountPage from 'pages/admin/account';


import DoctorLoginPage from 'pages/doctor/login';

import DoctorTranscriptionsPage from 'pages/doctor/transcriptions'
import DoctorTranscriptionPage from 'pages/doctor/transcription'

import DoctorTelemedicineCallPage from 'pages/doctor/telemedicine-call'
import DoctorTelemedicineCallsPage from 'pages/doctor/telemedicine-calls'
import VideoPage from 'pages/doctor/video'

import DoctorEmailInboxPage from 'pages/doctor/email-inbox'
import DoctorEmailThreadPage from 'pages/doctor/email-thread'

import DoctorForgotPasswordPage from 'pages/doctor/forgot-password';
import DoctorResetPasswordPage from 'pages/doctor/reset-password';

import client from './config/apollo'
import AuthenticationProvider, { AdminAuthenticatedRoute, AnalyticsAuthenticatedRoute, DoctorAuthenticatedRoute } from './contexts/authentication'
import HospitalProvider from './contexts/hospital'
import Insights from './pages/insights';
import * as Sentry from "@sentry/react";

// https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics/
import ReactGA from 'react-ga';
const TRACKING_ID = "G-9NHE495S13"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const { REACT_APP_ENVIRONMENT } = process.env;

Sentry.init({
  environment: REACT_APP_ENVIRONMENT,
  dsn: "https://2eb3c598cea909882dd1b711375d4b41@o4506543658827776.ingest.sentry.io/4506543678291968",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/transcription-frontend\.onrender\.com\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: REACT_APP_ENVIRONMENT == 'production'
});


function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <BrowserRouter>
            <Routes>
              <Route 
                path="/"
                element={<HomePage />}
              />
              <Route
                path="/doctor/login"
                element={<DoctorLoginPage />}
              />
              <Route
                path="/admin/login"
                element={<AdminLoginPage />}
              />
              <Route
                path="/doctor/forgot-password"
                element={<DoctorForgotPasswordPage />}
              />
              <Route
                path="/doctor/reset-password"
                element={<DoctorResetPasswordPage />}
              />
              <Route
                path="email"
              element={(
                <Navigate to="/email/inbox" />
              )}
              />
              <Route
                path="email/inbox"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorEmailInboxPage filter='inbox' />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              <Route
                path="email/sent"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorEmailInboxPage filter='sent' />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              <Route
                path="email/drafts"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorEmailInboxPage filter='drafts' />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              <Route
                path="email/all"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorEmailInboxPage filter='all' />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              <Route
                path="email/spam"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorEmailInboxPage filter='spam' />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              <Route
                path="email/trash"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorEmailInboxPage filter='trash' />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
            <Route
              path="email/:emailThreadId"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorEmailThreadPage />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
            />
            <Route
              path="transcriptions/:transcriptionId"
              element={(
                <Navigate to="/records" />
              )}
            />
            <Route
              path="records/:transcriptionId"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorTranscriptionPage />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
            />
              <Route
                path="transcriptions"
                element={(
                  <Navigate to="/records" />
                )}
              />
              <Route
                path="records"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorTranscriptionsPage />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              
              <Route
                path="telemedicine/:callId"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorTelemedicineCallPage />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              <Route
                path="telemedicine"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <DoctorTelemedicineCallsPage />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />
              <Route
                path="video/:token"
              element={(
                <DoctorAuthenticatedRoute>
                  <HospitalProvider>
                    <VideoPage />
                  </HospitalProvider>
                </DoctorAuthenticatedRoute>
              )}
              />

              <Route 
                path="insights"
              element={
                <AnalyticsAuthenticatedRoute>
                  <Insights />  
                </AnalyticsAuthenticatedRoute>
                }
              />
              <Route 
                path="admin/transcriptions/:transcriptionId"
              element={<Navigate to="/admin/records/" />}
              />
              <Route 
                path="admin/transcriptions/all"
              element={<Navigate to="/admin/records/all" />}
              />
              <Route 
                path="admin/transcriptions"
              element={<Navigate to="/admin/records" />}
              />
              <Route 
                path="admin/records/:transcriptionId"
              element={<AdminAuthenticatedRoute><AdminTranscriptionPage /></ AdminAuthenticatedRoute>}
              />
              <Route 
                path="admin/records/all"
              element={<AdminAuthenticatedRoute><AdminTranscriptionsPage filter='all' /></AdminAuthenticatedRoute>}
              />
              <Route 
                path="admin/records"
              element={<AdminAuthenticatedRoute><AdminTranscriptionsPage filter='proofreading' /></AdminAuthenticatedRoute>}
              />
              <Route 
                  path="admin/accounts/:accountId"
                element={<AdminAuthenticatedRoute><AdminAccountPage /></AdminAuthenticatedRoute>}
                />
              <Route 
                path="admin/accounts"
              element={<AdminAuthenticatedRoute><AdminAccountsPage /></AdminAuthenticatedRoute>}
              />
              <Route 
                path="checkout_success"
                element={<CheckoutSuccessPage />}
              />
              <Route 
                path="checkout_failure"
                element={<CheckoutFailurePage />}
              />
            </Routes>
        </BrowserRouter>
      </AuthenticationProvider>
    </ApolloProvider>
  );
}

export default App;
