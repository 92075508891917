import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { COMPLETE_TRANSCRIPTION_MUTATION, SAVE_SOAP_MUTATION } from './queries'

const SoapTab = ({ transcription, refetch }) => {

  const [soap, setSoap] = useState()
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    setSoap(transcription.soapContent)

  }, [transcription])

  const [completeTranscription, _] = useMutation(COMPLETE_TRANSCRIPTION_MUTATION, {
    errorPolicy: "all",
  })

  const [saveSoap, _2] = useMutation(SAVE_SOAP_MUTATION, {
    errorPolicy: "all",
  })

  const onComplete = () => {
    completeTranscription({
      variables: {
        transcriptionId: transcription.id,
      },
      onCompleted: () => {
        console.log('complete')
        refetch()
        // setCurrentTranscription(null)
        // refetchList()
      }
    })
  }

  const onSave = (regenerateEmail = false, writeToPims = false) => {
    saveSoap({
      variables: {
        transcriptionId: transcription.id,
        soapContent: soap,
        regenerateEmail: regenerateEmail,
        writeToPims: writeToPims
      },
      onCompleted: () => {
        refetch()
        // setCurrentTranscription(null)
        // refetchList()
      }
    })
  }

  const copyNoteToClipboard = () => {
    navigator.clipboard.writeText(soap)
    setCopied(true)
  }

  return (
    <div>
      <textarea
        className={`w-full mb-4 rounded-lg border-gray-300 ${transcription.status == 'Generating SOAP' && 'bg-gray-200'}`}
        placeholder="Edit SOAP Notes here..."
        value={soap}
        disabled={transcription.status == 'Generating SOAP'}
        rows={15}
        onChange={(e) => setSoap(e.target.value)}
      />
      <div>
        <button onClick={copyNoteToClipboard} className='text-white bg-black px-4 py-2 rounded-md mr-2'>Click to Copy</button>
        {transcription.status != 'completed' && (
          <button onClick={onComplete} className='text-white bg-black px-4 py-2 rounded-md mr-2'>Mark Complete</button>
        )}
        {/* disable save, even if pims rewriteable, so they dont get out of sync */}
        <button onClick={() => onSave(true, false)} disabled={transcription.integrationSoapWriteable && !transcription.soapNotYetWrittenToPims} className={`text-white ${transcription.integrationSoapWriteable && !transcription.soapNotYetWrittenToPims ? 'bg-gray-300 cursor-not-allowed' : 'bg-black'} px-4 py-2 rounded-md mr-2`}>Save + Regenerate Email</button>
        {transcription.integrationSoapWriteable &&
          <button onClick={() => onSave(true, true)} disabled={!transcription.soapNotYetWrittenToPims && !transcription.integrationSoapRewriteable} className={`text-white ${!transcription.soapNotYetWrittenToPims && !transcription.integrationSoapRewriteable ? 'bg-gray-300 cursor-not-allowed' : 'bg-black'} px-4 py-2 rounded-md`}>Write to PIMS</button>
        }
      </div>
      {transcription.integrationStatus == 'completed' && <p className='text-green-700 font-medium text-sm'>Successfully written to PIMS</p>}
      {transcription.integrationStatus == 'started' && <p className='text-yellow-700 font-medium text-sm'>Writing to PIMS...</p>}
      {copied && <p className='text-green-700 font-medium text-sm'>Copied!</p>}
    </div>
  )
}

export default SoapTab
