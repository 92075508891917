import MainLayout from 'components/admin/main-layout';
import Content from './content'
import { Link, useLocation } from 'react-router-dom'

const LeftBar = () => {
  const { pathname } = useLocation()
  return (
    <div className='inset-x-0 w-72 py-8 px-4 h-full'>
      <Link to='/admin/records'><p className={`${pathname == '/admin/records' ? 'font-bold' : 'font-normal'} text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-2`}>Inbox</p></Link>
      <Link to='/admin/records/all'><p className={`${pathname == '/admin/records/all' ? 'font-bold' : 'font-normal'} text-base cursor-pointer hover:font-semibold leading-6 text-gray-900 mb-2`}>All Medical Records</p></Link>
    </div>
  )
}


const AdminTranscriptions = ({ filter }) => {

  return (
    <MainLayout>
      <div className='flex flex-row h-100 w-full' style={{ 'height': 'calc(100vh - 4rem)' }}>
          <LeftBar/>
          <Content filter={filter} />
      </div>
    </MainLayout>
  )

}

export default AdminTranscriptions