import { gql } from "@apollo/client"


export const ADMIN_GENERATE_PAYMENT_LINK_MUTATION = gql`
  mutation AdminGeneratePaymentLink(
    $accountId: ID!
    $hardwareCount: Int!
    $licenseCount: Int!
  ) {
    adminGeneratePaymentLink(
      accountId: $accountId
      hardwareCount: $hardwareCount
      licenseCount: $licenseCount
    ) {
      paymentUrl
    }
  }
`
