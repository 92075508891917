import { gql } from "@apollo/client";

export const GET_EMAIL_THREADS_QUERY = gql`
  query EmailThreads {
    emailThreads {
        id
        googleId
        correspondent
        snippet
        latestSentAt
        latestIsInbound
        isRead
        subject
        labels
        friendlyLatestSentAt
    }
  }
`;