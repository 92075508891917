import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { FORGOT_PASSWORD_MUTATION } from './queries.js'

import { useAuthentication } from '../../../contexts/authentication'
import PatchEducationLogo from '../../../assets/patch-logo-white.png'

const { REACT_APP_PRODUCTION_SERVER_URL } = process.env;

const ForgotPasswordPage = () => {
  const { currentUser } = useAuthentication()

  const [message, setMessage] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (currentUser) {
      window.location = '/course'
    }
  })

  const [forgotPassword, _] = useMutation(FORGOT_PASSWORD_MUTATION, {
    errorPolicy: "all",
    onError: (e) => {
      console.log(e)
    },
    onCompleted: (data) => {
      setMessage('A password reset link has been sent to your email.')
    },
    variables: {
      email,
    }
  })

  const onSubmit = (e) => {
    e.preventDefault()
    if(email) {
      forgotPassword()
    }
  }

  return (
    <div className='h-screen w-full bg-slate-800'>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-16 w-auto" src={PatchEducationLogo} alt="Patch Education" />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-slate-100">Forgot Password</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <div className="mt-2">
                  <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" autoComplete="email" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                </div>
              </div>
              {!message ? (
                <div>
                  <button disabled={!email} onClick={onSubmit} type="submit" className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FF3F44]">Submit</button>
                </div>
              ) : (
                  <div className='w-full flex flex-col items-center'>
                    <p className='text-sm'>{message}</p>
                  </div>
              )}
            </form>
          </div>
          <p className="mt-10 text-center text-sm text-slate-500">
            Not a member?
            <a href={`${REACT_APP_PRODUCTION_SERVER_URL}/checkout`} className="font-semibold leading-6 text-white hover:opacity-80 ml-2">Sign up</a>
          </p>
        </div>
      </div>
    </div>
  )

}

export default ForgotPasswordPage