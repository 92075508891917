import { gql } from "@apollo/client"

export const COMPLETE_TRANSCRIPTION_MUTATION = gql`
  mutation CompleteTranscription(
    $transcriptionId: ID!
  ) {
    completeTranscription(
      transcriptionId: $transcriptionId
    ) {
      id
    }
  }
`
