import { GET_ADMIN_TRANSCRIPTION_LIST } from './queries'
import { useQuery } from '@apollo/client'
import List from './list'
import Pagination from './pagination'
import { useSearchParams } from 'react-router-dom'

const PAGE_SIZE = 20

const Content = ({ filter }) => {
  const [searchParams, _] = useSearchParams();
  const offset = parseInt(searchParams.get('page')) * PAGE_SIZE || 0


  const { loading, error, data } = useQuery(GET_ADMIN_TRANSCRIPTION_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: filter,
      limit: PAGE_SIZE,
      offset: offset
    }
  });

  if (loading) return null;
  if (error) return <span>Error : {error.message}</span>;

  return (
    <div className='flex flex-col w-full h-screen border-l overflow-y-scroll'>
      <List transcriptions={data?.adminTranscriptions} />
      {data.adminTranscriptionPages > 1 && <Pagination pages={data.adminTranscriptionPages} />}
    </div>
  )

}

export default Content