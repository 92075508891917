import { gql } from "@apollo/client"

export const ADMIN_CREATE_ACCOUNT_MUTATION = gql`
  mutation AdminCreateAccount(
    $name: String!
  ) {
    adminCreateAccount(
      name: $name
    ) {
      success
    }
  }
`
